import { JSX } from 'react/jsx-runtime';
import TagItem from './TagItem';

type TagGroupProps = {
  tagList: string[];
  tagLimit?: number;
  isPresentational?: boolean;
};

const TagGroup = (props: TagGroupProps) => {
  const { tagList, tagLimit = 100, isPresentational = false } = props;

  const limitedTagList: JSX.Element[] = [];

  tagList?.forEach((tagItem: string, index: any) => {
    if (index < tagLimit) {
      limitedTagList.push(
        <TagItem
          key={index}
          tagItem={tagItem}
          isPresentational={isPresentational}
        />
      );
    }
    return;
  });

  return <div className="tag-group">{limitedTagList}</div>;
};

export default TagGroup;
