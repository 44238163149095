import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ForgotPasswordPage from './containers/authContainers/ForgotPasswordPage';
import ResetPasswordPage from './containers/authContainers/ResetPasswordPage';
import Layout from './hoc/Layout';
import ProtectedRoutes from './hoc/ProtectedRoutes';
import './scss/index.scss';
import { setCurrentUserflow } from './store/reducers/navigationReducer';

import LoadAboutPage from './containers/AboutPage';

const LoadHomePage = lazy(() => import('./containers/HomePage'));

const LoadLoginOrSignUpPage = lazy(
  () => import('./containers/authContainers/LoginOrSignUpPage')
);
const LoadLoginPage = lazy(
  () => import('./containers/authContainers/LoginPage')
);
const LoadSearchResultsPage = lazy(
  () => import('./containers/SearchResultsPage')
);
const LoadSignUpPage = lazy(
  () => import('./containers/authContainers/SignUpPage')
);

const LoadDonationPage = lazy(() => import('./containers/DonatePage'));

const LoadBlogPage = lazy(() => import('./containers/blogContainers/BlogPage'));

const LoadSeeAllBlogsPage = lazy(() => import('./containers/SeeAllBlogsPage'));

const LoadUploadEditBlogPage = lazy(
  () => import('./containers/blogContainers/UploadEditBlogPage')
);

const LoadReviewBlogPage = lazy(
  () => import('./containers/blogContainers/ReviewBlogPage')
);

const LoadBlogDiscussionPage = lazy(
  () => import('./containers/blogContainers/BlogDiscussionPage')
);

const LoadCreateProfilePage = lazy(
  () => import('./containers/profileContainers/CreateProfilePage')
);

const LoadProfilePage = lazy(
  () => import('./containers/profileContainers/ProfilePage')
);

const LoadEditProfilePage = lazy(
  () => import('./containers/profileContainers/EditProfilePage')
);

const LoadContactForm = lazy(() => import('./containers/ContactForm'));

const LoadBlogReviewForm = lazy(
  () => import('./containers/blogContainers/BlogReviewForm')
);

const LoadAdminPage = lazy(() => import('./containers/AdminPage'));

const LoadNewsFeedPage = lazy(
  () => import('./containers/NewsFeedPage')
);

const fallbackLoader = (
  <div className="center-loader">
    <div className="duck-loader" />
  </div>
);

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn);
  const isStaff = useSelector((state: AppState) => state.user.isStaff);

  useEffect(() => {
    switch (location.pathname) {
      case '/forgot-password':
      case '/login-or-signup':
      case '/login':
      case '/sign-up':
      case '/create-profile':
        dispatch(setCurrentUserflow('auth-flow'));
        break;
      default:
        dispatch(setCurrentUserflow('home-flow'));
        break;
    }
    if (location.pathname.includes('/password-reset/')) {
      dispatch(setCurrentUserflow('auth-flow'));
    }
  }, [dispatch, location]);

  return (
    <Layout>
      <Suspense fallback={fallbackLoader}>
        <Routes>
          {/* Protected Routes */}
          <Route element={<ProtectedRoutes isAuthenticated={isLoggedIn} />}>
            <Route path="/edit-profile" Component={LoadEditProfilePage} />
            <Route path="/update-profile" Component={LoadEditProfilePage} />
            <Route path="/contact-us" Component={LoadContactForm} />
            <Route path="/upload-blog" Component={LoadUploadEditBlogPage} />
            <Route path="/edit-blog" Component={LoadUploadEditBlogPage} />
            <Route path="/review-blog-upsert" Component={LoadReviewBlogPage} />
            <Route path="/profile" Component={LoadProfilePage} />
            <Route
              path="/review-blog/:blog_slug/:blog_id"
              Component={LoadBlogReviewForm}
            />
          </Route>

          {/* Staff Routes */}
          <Route element={<ProtectedRoutes isAuthenticated={isStaff} />}>
            <Route path="/admin-wn" Component={LoadAdminPage} />
            <Route path="/upload-article" Component={LoadUploadEditBlogPage} />
            <Route path="/edit-article" Component={LoadUploadEditBlogPage} />
            <Route
              path="/review-article-upsert"
              Component={LoadReviewBlogPage}
            />
          </Route>

          {/* Auth Routes */}
          <Route path="/create-profile" Component={LoadCreateProfilePage} />
          <Route
            path="/login-or-signup"
            element={
              isLoggedIn ? (
                <Navigate to="/home" replace />
              ) : (
                <LoadLoginOrSignUpPage />
              )
            }
          />
          <Route path="/sign-up" Component={LoadSignUpPage} />
          <Route
            path="/login"
            element={
              isLoggedIn ? <Navigate to="/home" replace /> : <LoadLoginPage />
            }
          />
          <Route
            path="/forgot-password"
            element={
              isLoggedIn ? (
                <Navigate to="/home" replace />
              ) : (
                <ForgotPasswordPage />
              )
            }
          />
          <Route
            path="/password-reset/:uid/:token"
            Component={ResetPasswordPage}
          />

          {/* Public Routes */}
          <Route path="/home" Component={LoadHomePage} />
          <Route path="/newsfeed" Component={LoadNewsFeedPage} />
          <Route path="/search-results" Component={LoadSearchResultsPage} />
          <Route path="/blog/:blog_slug/:blog_id" Component={LoadBlogPage} />
          <Route path="/article/:blog_slug/:blog_id" Component={LoadBlogPage} />
          <Route path="/about" Component={LoadAboutPage} />
          <Route path="/about" Component={LoadAboutPage} />
          <Route
            path="/blog/:blog_slug/:blog_id/discussion/:discussion_slug"
            Component={LoadBlogDiscussionPage}
          />
          <Route path="/contact-us" Component={LoadContactForm} />
          <Route path="/donate" Component={LoadDonationPage} />
          <Route
            path="/blog/:blog_slug/:blog_id/discussion/:discussion_slug/:discussion_id"
            Component={LoadBlogDiscussionPage}
          />
          <Route path="/blogs/:list_slug" Component={LoadSeeAllBlogsPage} />
          <Route path="/articles/:list_slug" Component={LoadSeeAllBlogsPage} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
