import { createSlice } from '@reduxjs/toolkit';

import { getFeaturedArticles } from '../../actions/blogListActions';
import {
  blogListDefaultCases as articleListCases,
  initBlogListType,
} from './blogListReducerUtils';

const initialState = initBlogListType() as BlogListStateType;

const articleFeaturedListSlice = createSlice({
  name: 'articleFeaturedListSlice',
  initialState,
  reducers: {
    resetShuffledBlogListState: () => initialState,
  },
  extraReducers: builder => {
    articleListCases(builder, getFeaturedArticles);
  },
});

export const { resetShuffledBlogListState } = articleFeaturedListSlice.actions;
export default articleFeaturedListSlice.reducer;
